import uuid from "./uuidInterface";
import { COOKIE_VERSION, DEFAULT_COOKIE_AGE, DEFAULT_DOMAIN } from "./constants/cookie-constants";
import CookieDefinitions from "./definitions/cookie-definitions"
import { Log } from "./logger";

function removeDotPrefix(domain: string): string {
    if(domain.charAt(0) === '.')
        // remove '.' from the domain
        domain = domain.slice(1);
    return domain;
}

export function isDomainConfigurationValid(domain = DEFAULT_DOMAIN, source: string, log?: Log) {
    const cleanedDomain = removeDotPrefix(domain);
    const hostname = window.location.hostname;
    if (!hostname.endsWith(cleanedDomain)) {
        // publish metric that captures how many users tried to set their preference but failed
        const error = log ? log("error") : () => { };
        error("domainMismatch", { detail: "Domain mismatch", source: source, configuredDomain: domain, actualDomain: hostname })

        console.error(`Shortbread failed to set user's cookie preference because the domain name that was passed in does not match the hostname of the application. 
        Configured domain: ${domain}.
        Actual domain: ${hostname}.
        As a fallback, Shortbread is only allowing 'essential' cookies to be used.`);
        return false;
    }
    return true;
}

function unpack(c: CookieDefinitions.CompressedConsentCookie): CookieDefinitions.InternalConsentCookie {
    return {
        essential: c.e === 1,
        performance: c.p === 1,
        functional: c.f === 1,
        advertising: c.a === 1,
        id: c.i,
        version: c.v
    };
}

function pack(c: CookieDefinitions.InternalConsentCookie): CookieDefinitions.CompressedConsentCookie {
    return {
        e: c.essential ? 1 : 0,
        p: c.performance ? 1 : 0,
        f: c.functional ? 1 : 0,
        a: c.advertising ? 1 : 0,
        i: c.id,
        v: c.version
    };
}

function isValidCookie(cookie: any): cookie is CookieDefinitions.CompressedConsentCookie {
    // Also ensure essential is always true
    if (cookie.e !== 1) return false;
    if (typeof cookie.p !== "number") return false;
    if (typeof cookie.f !== "number") return false;
    if (typeof cookie.a !== "number") return false;
    if (typeof cookie.i !== "string") return false;
    return typeof cookie.v === "string";
}

function getConsentCookieInternal(cookiesString: string, log?: Log): CookieDefinitions.InternalConsentCookie | undefined {
    const value = cookiesString.match("(^|;)\\s*awsccc\\s*=\\s*([^;]+)");
    const error = log ? log("error") : () => { };
    if (value && value.length > 0) {
        try {
            const decoded = JSON.parse(atob(value[value.length - 1]));
            if (isValidCookie(decoded)) {
                return unpack(decoded);
            }
            error("getCookie", { detail: "Cookie format is not valid", cookie: decoded })
            return undefined;
        } catch (err) {
            error("getCookie", { detail: "Error parsing cookie", cookie: value[value.length - 1] })
            return undefined;
        }
    }
    return undefined;
}

export function getConsentCookie(cookiesString = () => document.cookie, log: Log): CookieDefinitions.ConsentCookie | undefined {
    const value = getConsentCookieInternal(cookiesString(), log);
    if (value) {
        return {
            essential: value.essential,
            performance: value.performance,
            functional: value.functional,
            advertising: value.advertising
        }
    }
    return undefined
}

function getId(cookiesString = () => document.cookie): string | undefined {
    const cookieValue = getConsentCookieInternal(cookiesString());
    if (cookieValue && cookieValue.id) {
        return cookieValue.id
    }
    return undefined
}

function defaultWriter(cookie: string) {
    document.cookie = cookie;
    return;
}

export function setConsentCookie(
    consent: CookieDefinitions.ConsentCookie,
    domain = DEFAULT_DOMAIN,
    maxAge = DEFAULT_COOKIE_AGE,
    uuidInterface: (log?: Log, uuidGenerator?: () => string, uuidFallback?: () => string) => string = uuid,
    writer = defaultWriter,
    log?: Log,
    uuidGenerator?: () => string,
    uuidFallback?: () => string
): CookieDefinitions.InternalConsentCookie {
    isDomainConfigurationValid(domain, 'customize', log);
    const id = getId() || uuidInterface(log, uuidGenerator, uuidFallback);
    const consentCookie = {
        ...consent,
        id, // use id if available, else generate it
        version: COOKIE_VERSION
    }
    const packedCookie = pack(consentCookie)
    writer(`awsccc=${btoa(JSON.stringify(packedCookie))}; domain=${domain}; path=/; max-age=${maxAge}; secure=true; SameSite=Lax`)
    return consentCookie
}