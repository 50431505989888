import {act} from "../../jsx-act";
import ConsentInfo from './consent-info'
import ConsentSection from './consent-section'
import ConsentLegal from './consent-legal'
import ConsentFooter from './consent-footer'
import UXDefinitions from '../../definitions/ux-definitions'
import {CUSTOMIZE_ID, TABTRAP_ID, CUSTOMIZE_SAVE_BTN_ID} from "../../constants/ux-constants";
import './consent-selector.scss'
import CookieDefinitions from "../../definitions/cookie-definitions";

interface ConsentProps {
    handleSaveClick: UXDefinitions.HandleSaveClick,
    handleCancelClick: UXDefinitions.HandleCancelClick,
    handleCheckboxToggle: UXDefinitions.HandleCheckboxToggle,
    localizedText: UXDefinitions.ConsentSelectorLocalization,
    closeConsentSelector: () => void,
    consentState: CookieDefinitions.ConsentCookie,
    darkModeEnabled?: boolean,
    runtimeEnvironment?: UXDefinitions.RuntimeEnvironment
}

export default ({handleSaveClick, handleCancelClick, handleCheckboxToggle, localizedText, closeConsentSelector, consentState, darkModeEnabled = false, runtimeEnvironment = 'default'}: ConsentProps) => {
    /**
     * The purpose of the tab trap is to keep users in the
     * modal when tabbing. This prevents users from tabbing
     * to elements behind the modal. Instead tabbing from the last
     * modal element will next focus on the first modal element.
     */
    const handleLastTabTrap = () => {
        document.querySelector<HTMLElement>(`div[data-id=${TABTRAP_ID}]`)!.focus({
            preventScroll: true
        });
    }
    
     /**
     * The purpose of this function is to keep users in the modal when
     * moving focus backwards with Shift + Tab keystrokes.
     * Once outside the tab trap while tabbing backwards,
     * the focus will be on the last element 
     */
    const handleOutsideTabTrap = () => {
        document.querySelector<HTMLElement>(`button[data-id=${CUSTOMIZE_SAVE_BTN_ID}]`)!.focus({
            preventScroll: true
        })
    }

    const handleModalClick = (event) => {
        if (event.target.hasAttribute('data-awsccc-modal-toggle')) {
            handleCancelClick("preferencesModal")
        }
    }
    
    return (
        <div data-id={CUSTOMIZE_ID} style={{display: 'none'}} tabindex="0" events={{onfocus: handleOutsideTabTrap}} class={darkModeEnabled === true ? 'dark-mode-enabled' : ''}>
            <div id='awsccc-cs-container' role="dialog" aria-modal="true" data-awsccc-modal-toggle events={{onclick: handleModalClick}} data-id={TABTRAP_ID} tabindex="-1">
                <div id='awsccc-cs-container-inner'>
                    <div id='awsccc-cs-content'>
                        <div id='awsccc-cs-header'>
                            <div id='awsccc-cs-title'>
                                <h2>{localizedText.header}</h2>
                            </div>
                        </div>
                        <div id='awsccc-cs-modalBody'>
                            <ConsentInfo localizedText={localizedText.intro} />
                            <ConsentSection 
                                category="essential"
                                content={localizedText["section-essential"]}
                                isDisabled
                                isChecked={consentState.essential}
                                handleCheckboxToggle={handleCheckboxToggle}
                                localizedLabelText={localizedText["checkbox-label"]}
                            />
                            <ConsentSection 
                                category="performance"
                                content={localizedText["section-performance"]}
                                isChecked={consentState.performance}
                                handleCheckboxToggle={handleCheckboxToggle}
                                localizedLabelText={localizedText["checkbox-label"]}
                            />
                            <ConsentSection 
                                category="functional"
                                content={localizedText["section-functional"]}
                                isChecked={consentState.functional}
                                handleCheckboxToggle={handleCheckboxToggle}
                                localizedLabelText={localizedText["checkbox-label"]}
                            />
                            <ConsentSection 
                                category="advertising"
                                content={localizedText["section-advertising"]}
                                isChecked={consentState.advertising}
                                handleCheckboxToggle={handleCheckboxToggle}
                                localizedLabelText={localizedText["checkbox-label"]}
                            />
                            <ConsentLegal localizedText={runtimeEnvironment === 'mobile' ? localizedText['footer-mobile'] : localizedText.footer } />
                        </div>
                        <ConsentFooter handleSaveClick={handleSaveClick} handleCancelClick={handleCancelClick} localizedText={localizedText}/>
                    </div>
                </div>
            </div>
            <div id='awsccc-cs-modalOverlay'/>
            <div data-id={TABTRAP_ID} tabindex="-1" class="awsccc-tab-helper" events={{onfocus: handleLastTabTrap}}/>
        </div>
    )
};
